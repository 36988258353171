.App {
}

/* .all-page-container {
  display: flex;
  justify-content: center;
} */

input[type="checkbox"] {
  cursor: pointer;
}

input[type="radio"] {
  cursor: pointer;
}

label:hover {
  background: #ddd;
  cursor: pointer;
}

label:has(input:disabled) {
  background: none;
  cursor: not-allowed;
}

.origin-file-download {
  cursor: pointer;
}

.origin-file-download:hover {
  color: crimson;
}

input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input {
  height: 22px !important;
  border-radius: 3px;
}

.wrapper {
  width: 200px;
  height: 150px;
}

select {
  overflow-y: auto;
}
option {
  overflow-y: scroll;
}

.textarea {
  height: auto !important;
}

.form-input {
  height: auto !important;
}

.ant-input::placeholder {
  font-size: 0.7rem !important;
}

.money-form-input {
  height: 20px !important;
  font-size: 0.8rem;
}

.teen-number-input {
  height: 20px !important;
  font-size: 0.8rem;
  text-align: right;
}

.eleven-input-number {
  height: 20px !important;
  font-size: 0.8rem;
  text-align: right;
}

.twelve-input-number {
  height: 20px !important;
  font-size: 0.8rem;
  text-align: right;
}

.fifteen-input-gita {
  height: 20px !important;
  width: 250px;
  font-size: 0.8rem;
  margin-left: 20px;
  padding: 0px 2px;
}

.fifteen-input-number {
  height: 20px !important;
  font-size: 0.8rem;
  text-align: right;
  padding: 0px 3px;
  width: 80px;
}

.eighteen-input-gita {
  height: 18px !important;
  width: 180px;
  font-size: 0.8rem;
  margin-left: 8px;
  padding: 0px 2px;
}

.origin-file-download {
  cursor: pointer;
}
.origin-file-download:hover {
  color: crimson;
}

.form-input::placeholder {
  font-size: 0.8rem !important;
}

html,
body {
  height: auto !important;
}

.start-download {
  color: rgb(161, 199, 106);
  cursor: pointer;
}

/* .start-download:hover {
  color: crimson;
} */

.fa-copy {
  cursor: pointer;
}

.fa-copy:hover {
  color: crimson;
}

.ant-table-selection > label:hover {
  background: none !important;
}

.ant-table-cell > label:hover {
  background: none !important;
  cursor: not-allowed !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 파이어폭스에서의 초기화 방법 */
input[type="number"] {
  -moz-appearance: textfield;
}

.submit-form-h2 {
  margin: 0px !important;
}

.submit-form-div {
  cursor: pointer;
}

.submit-form-div:hover {
  color: crimson;
}

.submit-form-span {
  font-weight: 500;
  margin-right: 5px;
}

.admin-code {
  cursor: pointer;
}
.admin-code:hover {
  color: crimson;
}

.ant-notification {
  z-index: 1000 !important;
}

.fa-ellipsis-v {
  cursor: pointer;
  padding: 4px 8px;
}

.fa-ellipsis-v:hover {
  font-weight: bolder;
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

* {
  font-family: "Noto Sans KR", sans-serif !important;
}

.fas {
  font-family: "Font Awesome 5 Free" !important;
}
